@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-indicator:before {
  content: "";
  background: #00000080;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: " ";
  position: fixed;
  top: 40%;
  left: 45%;
  z-index: 10010;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #0474bf; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

/* Appliquer à toute la grille */
.ag-theme-alpine {
  font-size: 1rem !important;
}

/* Appliquer uniquement aux cellules */
/* .ag-theme-alpine .ag-cell {
  font-size: 0.875rem !important;
} */

.ag-theme-alpine {
  min-height: 10px !important;
}

.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-viewport,
.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine .ag-layout-print .ag-center-cols-viewport,
.ag-theme-alpine .ag-layout-print .ag-center-cols-container,
.ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-viewport,
.ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine-dark .ag-layout-print .ag-center-cols-viewport,
.ag-theme-alpine-dark .ag-layout-print .ag-center-cols-container,
.ag-theme-alpine-auto-dark .ag-layout-auto-height .ag-center-cols-viewport,
.ag-theme-alpine-auto-dark .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine-auto-dark .ag-layout-print .ag-center-cols-viewport,
.ag-theme-alpine-auto-dark .ag-layout-print .ag-center-cols-container {
  min-height: 50px !important;
}

#root > div.modal.modal-open {
  z-index: auto;
  min-width: 500;
}

#root
  > div.fixed.overflow-hidden.z-20.bg-gray-900.bg-opacity-25.inset-0.transform.ease-in-out.transition-opacity.opacity-100.duration-500.translate-x-0
  > section.w-100.md\:w-96.right-0.absolute.bg-base-100.h-full.shadow-xl.delay-400.duration-500.ease-in-out.transition-all.transform.translate-x-0 {
  transition-duration: 100ms !important;
}
